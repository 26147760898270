import {AppConfiguration, SurveyQuestionType} from "@hec/models";
import {AxiosInstance} from "axios";
import {
  ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType,
  ConfigurationTemplateModelType, DormerColorVisualizationType, FrameOverlayAssemblyType, FrameOverlaysType,
  FrameVariation, ModifyGroupIndex, RoofTrimVariation, SectionFragmentSide,
  SectionFragmentType,
  Side
} from '@hec/api-dtos';
import axios from 'axios';

export abstract class BaseDalService {
  private customerAuthInterceptorId: number | undefined;
  private orderConfigurationCode: string | undefined;
  protected static serviceName = Symbol("SERVICES.BASE_DAL_SERVICE");
  hasFailedRefresh = false;

  static getName(): symbol {
    return BaseDalService.serviceName;
  }

  configure(configuration: AppConfiguration): void {
    console.log(configuration);
  }


  public useBearerAuthentication(client: AxiosInstance, configuration: AppConfiguration) {
    try {
      const refreshToken = configuration.login?.refreshToken ?? null;

      if (refreshToken != null && !this.hasFailedRefresh) {
        const tokenEndpoint = `${configuration.login?.host}/realms/${configuration.login?.realm}/protocol/openid-connect/token`;
        const clientId = configuration.login?.clientId ?? '';

        client.interceptors.request.use(async (config) => {
          const params = new URLSearchParams();
          params.append('client_id', clientId);
          params.append('grant_type', 'refresh_token');
          params.append('refresh_token', refreshToken);

          try {
            const response = await axios.post(tokenEndpoint, params, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });

            const { access_token } = response.data;
            config!.headers!.Authorization = `Bearer ${access_token}`;
            return config;
          } catch (error) {
            console.error('Failed to refresh token', error);
            this.hasFailedRefresh = true
            return Promise.reject(error);
          }
        }, error => {
          this.hasFailedRefresh = true
          return Promise.reject(error);
        });

      }
    } catch (e) {
      this.hasFailedRefresh = true
      console.warn(e);
    }
  }

  configureAuthInterceptor(client: AxiosInstance, occ: string) {
    const orderConfigurationCode = occ;
    if(orderConfigurationCode === this.orderConfigurationCode) {
      return;
    }

    if (this.customerAuthInterceptorId !== undefined) {
      client.interceptors.request.eject(this.customerAuthInterceptorId);
    }

    this.customerAuthInterceptorId = client.interceptors.request.use((config) => {
      config.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `ConfigurationCode ${orderConfigurationCode}`
      };
      return config;
    });

    this.orderConfigurationCode = `${orderConfigurationCode}`;
  }


  // For conversion of ISO string date time's into javascript native datetimes
  public useDateTimeConverters(client: AxiosInstance): void {
    client.interceptors.response.use(
      (response) => {
        // Check if the response data contains date strings (modify this check as needed)
        if (response.data && typeof response.data === 'object') {
          this.recursivelyConvertToDate(response.data);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }


  private isISOString(str: string) {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/;
    return isoDatePattern.test(str);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private recursivelyConvertToDate(data: any) {
    for (const key in data) {
      if (data[key] && typeof data[key] === 'object') {
        this.recursivelyConvertToDate(data[key]);
      } else if (typeof data[key] === 'string' && this.isISOString(data[key])) {
        data[key] = new Date(data[key]);
      }
    }
  }

  //For conversion of all our generic enums
  public useEnumConverters(client: AxiosInstance): void {
    client.interceptors.response.use(
      (response) => {
        if (response.data && typeof response.data === 'object') {
          this.recursivelyConvertEnums(response.data);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // Function to recursively convert enum strings to enum values
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private recursivelyConvertEnums(data: any) {
    for (const key in data) {
      if (data[key] && typeof data[key] === 'object') {
        this.recursivelyConvertEnums(data[key]);
      } else if (
        typeof data[key] === 'string'
        //  && this.isEnumString(data[key], Side)
        // matchingEnum
      ) {

        //TODO: Add compatiablity for:
        // Currency
        // Flagman
        // FrameHeaderStyle
        // FrameOverlaysType
        // PlacementType
        // ProductionMaterialType
        // ProductType
        // RoofLightPlacementType
        // RoofLightTopType
        // RoofLightWindowType
        // RoofOverhangStyle
        // RoofTrimStyle
        // SectionFragmentType
        // SizeLabel
        // TextureMaterialType
        // Currency
        // OfferingType
        // LicenseRenewal


        if (key === 'side' && this.isEnumString(data[key], Side)) {
          data[key] = this.enumFromString(data[key], Side);
        } else if (key === 'sectionFragmentType' && this.isEnumString(data[key], SectionFragmentType)) {
          data[key] = this.enumFromString(data[key], SectionFragmentType);
        } else if (key === 'modelType' && this.isEnumString(data[key], ConfigurationTemplateModelType)) {
          data[key] = this.enumFromString(data[key], ConfigurationTemplateModelType);
        } else if (key === 'variation' && this.isEnumString(data[key], FrameVariation)) {
          data[key] = this.enumFromString(data[key], FrameVariation);
        } else if (key === 'surveyQuestionType' && this.isEnumString(data[key], SurveyQuestionType)) {
          data[key] = this.enumFromString(data[key], SurveyQuestionType);
        } else if (key === 'visualizationType' && this.isEnumString(data[key], ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType)) {
          data[key] = this.enumFromString(data[key], ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType);
        } else if (key === 'cheekSide' && this.isEnumString(data[key], SectionFragmentSide)) {
          data[key] = this.enumFromString(data[key], SectionFragmentSide);
        } else if (key === 'splinterSide' && this.isEnumString(data[key], SectionFragmentSide)) {
          data[key] = this.enumFromString(data[key], SectionFragmentSide);
        } else if (key === 'stubbedSide' && this.isEnumString(data[key], SectionFragmentSide)) {
          data[key] = this.enumFromString(data[key], SectionFragmentSide);
        } else if (key === 'roofTrimVariation' && this.isEnumString(data[key], RoofTrimVariation)) {
          data[key] = this.enumFromString(data[key], RoofTrimVariation);
        } else if (key === 'visualizationType' && this.isEnumString(data[key], DormerColorVisualizationType)) {
          data[key] = this.enumFromString(data[key], DormerColorVisualizationType);
        } else if (key === 'frameOverlaysType' && this.isEnumString(data[key], FrameOverlaysType)) {
          data[key] = this.enumFromString(data[key], FrameOverlaysType);
        } else if (key === 'frameOverlayAssemblyType' && this.isEnumString(data[key], FrameOverlayAssemblyType)) {
          data[key] = this.enumFromString(data[key], FrameOverlayAssemblyType);
        }
      }
      if (key === 'sectionFragmentTypesAllowedToSeparate') {
        data[key] = this.handleEnumArray(data, key, SectionFragmentType);
      } else if (key === 'modifyGroupsAllowedToSeparate') {
        data[key] = this.handleEnumArray(data, key, ModifyGroupIndex);
      }
    }
  }

  private handleEnumArray(data: any, key: string, enumType: any) {
    const result = data[key].map((item: string) => this.enumFromString(item, enumType));
    return result;
  }



  // Function to check if a string is a valid enum string
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private isEnumString(str: string, enumType: any) {
    return Object.values(enumType).includes(enumType[str]);
  }

  // Function to convert an enum string to its enum value
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private enumFromString(str: string, enumType: any) {
    return enumType[str];
  }

  protected checkConfiguration(configuration: AppConfiguration) {
    console.log(configuration);
    // assert(typeof configuration.baseUrl !== null, "Base url has not been set");
    // assert(typeof configuration.configurationId !== null, "Configuration id has not been set");
  }
}
